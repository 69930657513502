.EquipmentsMap {

  .equipment {
    display: flex;
    span {
      margin: auto 0;
    }
  }

  .tooltip-pellet{
    //margin-bottom: auto;
    //margin-top: auto;
    border-radius: 50%;
    height: 1rem;
    margin: 1rem;
    width: 1rem;
  }
  .greenPellet {
    background: var(--color-caribbean-green);
  }

  .yellowPellet {
    background: var(--color-salmon);
  }

  .redPellet {
    background: var(--color-radical-red);
  }
  .tooltip-container{
    border-radius: 0.8rem;
    display: none;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: -1.5rem;

    @extend %glasses_effect;
    .equipments__tooltip {

      @extend %glasses_effect;
    }
    button {
      color: var(--color-gray);
      font-weight: 500;
      min-width: 14rem;
      padding: 1rem;
      &:hover {
        background: var(--color-zircon);
        transition: all 200ms;
      }
    }
  }
  .map__wrapper {
    position: relative;
  }
  .map__search__equipment {
    @include position_el(absolute, 1rem, _, _, 1rem);
    z-index: 1;
    border-radius: 1.35rem;
  }
  .map {
    height: 93%;
    width: 100%;
  }

  .map__legend {
    background: var(--color-white);
    display: flex;
    height: 7%;
    padding: 0 5rem;
    div {
      color: var(--color-gray);
      margin-bottom: auto;
      margin-right: 8.4rem;
      margin-top: auto;
      position: relative;
      font-size: 1.3rem;
      &:before {
        background: green;
        border-radius: 50%;
        content: '';
        height: 1.4rem;
        left: -2rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 1.3rem;
      }

      &:nth-child(1) {
        &:before {
          background: var(--color-caribbean-green);
        }
      }
      &:nth-child(2) {
        &:before {
          background: var(--color-salmon);
        }
      }
      &:nth-child(3) {
        &:before {
          background: var(--color-radical-red);
        }
      }
    }
  }

  .pin-container {
    position: relative;
    .map-pin {

      @include position_el(absolute,_, _, 0, 50%, -50%, -50%);
      height: 4rem;
      width: 4rem;
    }
  }
}

