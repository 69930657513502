@import '../../styles/vars';

.Notifications {
  .ctn-inputs-date {
    display: flex;

    label {
      font-size: var(--font-size-medium);
      font-weight: 500;
      margin-bottom: 1rem;
    }

    .ctn-input-date {
      @extend %flex_column;
      margin-right: 3rem;
    }

    .ant-picker {
      border-radius: 30rem;
    }

    input {
      font-size: 1.4rem;
    }
  }
}

.DrawerNotifications {

  .detail {
    font-size: var(--font-size-large);
  }

  .Information {
    background-color: #54dd8f;
  }

  .Critique {
    background-color: #ff4d5d;
  }

  .Dégradé {
    background-color: #fe9d68;
  }

  .ctn_steps {
    padding: 0.75vw 1.5vw;
    border-radius: var(--border-radius-1);

    .ctn_step:first-child {
      margin-top: 1.5vw;
      padding-top: 0 !important;
    }

    .ctn_step:last-child {
      margin-bottom: 1.5vw;
      padding-bottom: 0 !important;
    }

    .progress_step {
      display: flex;
      padding-top: 1.5vw;
      padding-bottom: 1.5vw;
      width: 80%;
      border-left: 0.15vw solid grey;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        height: 1vw;
        width: 1vw;
        background-color: grey;
        left: -0.6vw;
        top: 50%;
        border-radius: 50%;
        transform: translateY(-50%);
        border: 0.2vw solid white;
      }
    }

    .done_step {
      display: flex;
      padding-top: 1.5vw;
      padding-bottom: 1.5vw;
      width: 80%;
      border-left: 0.15vw solid var(--color-dodger-blue);
      position: relative;

      &:before {
        content: '';
        position: absolute;
        height: 1vw;
        width: 1vw;
        background-color: var(--color-dodger-blue);
        left: -0.6vw;
        top: 50%;
        border-radius: 50%;
        transform: translateY(-50%);
        border: 0.2vw solid white;
      }
    }

    .step {
      width: 100%;
      font-size: var(--font-size-medium);
      display: flex;
      margin-left: 1.5vw;
      flex-direction: column;
      padding: var(--spacing-15);
      border-radius: var(--border-radius-1);

      span {
        font-size: var(--font-size-large);
        height: 1.5vw;
        align-items: center;
        padding: 0 var(--spacing-15);
        padding-top: 0.5rem;
        color: white;
        border-radius: 0.3vw;
        margin-right: auto;
        margin-bottom: var(--spacing-15);
      }


    }
  }

  h3 {
    font-size: var(--font-size-xl);

  }
}