

.Widgets {



  $rowHeight: 43.3rem;
  $titleWidgetFontSize: 2.4rem;
  $titleWidgetMarginBottom: 1.6rem;
  //grid-template-rows: minmax($rowHeight, $rowHeight);
  display: grid;
  grid-gap: 4.8rem;
  grid-template-columns: 42% 18.9% 34.3%;
  grid-template-rows: repeat(2, $rowHeight);
  margin-top: 5.3rem;
  grid-template-areas: "map td tc"
                        "map op wi";
  .EquipmentsMap {
    grid-area: map;
  }
  .TraficClassification {
    grid-area: tc;
  }
  .HorizontallyChart {
    grid-area: wi;
  }
  .Operators {
    grid-area: op;
  }
  .TransitIp {
    grid-area: td;
  }


  h3 {
    margin-bottom: 1.6rem;
  }
  & > div {
    & > div {
      //border: 1px solid red;
      @extend %light_shadow;
      background: var(--color-white);
      border-radius: 2.1rem;
      height: calc(100% - (2.4rem + 1.6rem) );
      overflow: hidden;
    }
  }
}

