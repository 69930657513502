.AddButton {
  color: var(--color-dodger-blue);
  display: flex;
  font-size: 1.2rem;
  svg {
    fill: var(--color-dodger-blue);
    height: 2rem;
    margin: auto 0.8rem auto;
  }
  span {
    margin: auto 0;
  }
}