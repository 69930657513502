.SettingsButton__dropdown {
  @extend %flex_column;
  @extend %glasses_effect;
  @extend %light_shadow;
  border-radius: 0.8vw;
  padding-top: 0!important;


  overflow: hidden;
  button {
    color: var(--color-gray);
    font-weight: 500;
    min-width: 14rem;
    padding: 1rem 5rem;
    display: flex;
    justify-content: center;
    position: relative;
    &:hover {
      background: var(--color-zircon);
      transition: all 200ms;
    }

    &:nth-child(3) {
      display: flex;
      svg {
        height: 2rem;
        margin: auto 1rem auto;
        position: absolute;
        top: 50%;
        left: 0.4vw;
        transform: translateY(-50%);
      }
    }
  }
}