.Layout {

  $topTitle: 5.5rem;
  //@extend %full_size;
  width: 100%;
  height: auto;
  background: var(--color-alabaster);
  display: flex;

  .content {
    //@extend %full_size;
    width: 100%;
    margin-left: 33rem;
    margin-top: 20rem;
    padding: 0 7.7rem 7.7rem 7.7rem;
  }

  .wrapper__title__page {
    height: 5.5rem;
    margin-bottom: 3.6rem;
    overflow: hidden;
    position: relative;
  }

  .title__page {
    display: flex;
    font-size: 4rem;
    font-weight: 600;
    position: absolute;
    top: $topTitle;
    transition: all 200ms;
    margin-top: 1rem;
    svg {
      fill: url(#MyGradient);
      height: 4rem;
      margin-right: 2.2rem;
    }
  }


  .current {
    transform: translateY(-$topTitle);
  }

}
