@mixin position_el($position, $top, $right, $bottom, $left, $x: 0, $y: 0) {
  bottom: $bottom;
  left: $left;
  position: $position;
  right: $right;
  top: $top;
  transform: translate($x, $y);
}


@mixin buttonRoundedLarge($bg, $color) {
  background: $bg;
  border-radius: 25vw ;
  color: $color;
  font-size: var(--font-size-xs);
  height: unset;
  margin: 0;
  padding: var(--spacing-10) var(--spacing-15);
}