.Operators {

  .ctn__chart__access__ip {
    //margin-top: 5rem;
    display: flex;
    height: 33rem;
    overflow: hidden;
    position: relative;

    & > div:nth-child(2) {
      height: 20%!important;
      margin-top: auto;
      text {
        tspan {
          font-size: var(--font-size-medium);
        }
      }
    }
  }

  .ChartOperator {
    height: 29rem!important;
    position: absolute;
    width: 100%!important;

    @extend %position_center;
    margin: 0 auto;
    top: calc(50% - 3rem)!important;
    & > div {
      left: 50%!important;
      transform: translateX(-50%);
    }
  }

  .ant-tabs-nav {
    border-bottom: none!important;
  }
  .ant-tabs-nav-list {
    justify-content: space-around;
    padding: 0 4rem;
    width: 100%;
  }
  .ant-tabs-tab {
    font-size: 1.6rem;
    font-weight: 500;
    padding-bottom: 0.8rem;
    padding-top: 1.6rem;
  }
}

