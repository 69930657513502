.SelectMultiple {
  font-size: 1.4rem;
  height: 4rem;
  width: 31.5rem;

  .ant-select-selector {
    border-radius: 0.7rem !important;
    padding: 0.5rem !important;
  }
  .ant-select-multiple .ant-select-selector::after {
    margin: 0 !important;
    line-height: 0 !important;
  }

  .ant-select-selection-overflow {
    margin-bottom: 10px !important;
    height: 100%;
  }

  .ant-select-selection-overflow-item {
    height: 100%;
  }


  .ant-select-selection-item-content {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .ant-select-selection-item {
    //background: red;
    border: solid #D9D9D9 1px !important;
    border-radius: 0.7rem;
    height: 100% !important;
    line-height: 100%;
    margin: 0 0.5rem 0 0;
    max-width: 20.5rem;

    span {
      margin: auto 0;

      &:nth-child(2) {
        margin-left: 0.6rem;
      }
    }
  }

}

.SelectMultiple__dropdown {
  @extend %glasses_effect;
  @extend %light_shadow;
  border-radius: 0.8vw;
  padding-top: 0 !important;

  .duration__list {
    @extend %flex_column;
  }

  .ant-select-item{
    font-weight: 500;
    min-width: 14rem;
    padding: 1.8rem!important;

    color: var(--color-gray);

    &:hover {
      background: var(--color-zircon);
      transition: all 200ms;
    }
  }
}