.Logout {
  display: flex;
}
.Navbar {


  background: linear-gradient(.4turn, #cfdefd 50%, #e4d7f5) !important;
  background: var(--color-white);
  height: 100vh;
  padding: 8.7rem 4.9rem;
  position: fixed;
  width: 33rem;

  display: flex;
  flex-direction: column;
  img {
    margin-bottom: 5.4rem;
    width: 22.8rem;
  }

  li {
    border-radius: 1.2rem;
    margin-bottom: 1.4rem;
    width: 100%;

    svg {
      fill: var(--color-woodsmoke);
      stroke: var(--color-woodsmoke);
    }
    &:hover {
      background: var(--color-white);
      transition: all  300ms;
    }
  }


  li[data-active="true"] {
    background: var(--color-dodger-blue);
    color: var(--color-white);
    svg {
      fill: var(--color-white);
      stroke: var(--color-white);
    }
    p {
      color: var(--color-white);
    }
  }

  a {
    display: flex;
    padding-bottom: 2.6rem;
    padding-left: 2.6rem;
    padding-top: 2.6rem;
  }

  svg {
    height: 1.8rem;
    margin-bottom: auto;
    margin-right: 1.2rem;
    margin-top: auto;
  }

  p {
    font-size: 1.8rem;
    margin-bottom: auto;
    margin-top: auto;
  }

  .logo__sayse {
    margin: auto auto 0 auto;
    width: 45%;
  }
}

.dark-theme {
  .Navbar {
    background: #1b1c1e!important;
    background: linear-gradient(.4turn, #2f354d 50%, #3a2826) !important;
  }
}
