.BasicDrawerForm {
  font-size: var(--font-size-medium);

  .error__collapse__form {
    border: 1mm solid var(--color-radical-red);
    border-radius: 16px;
  }

  .error__tabs__form {
    color: var(--color-radical-red) !important;
  }

  .error__field__form {

    &.ant-input-number,
    &.ant-input,
    .ant-select-selector {
      border: 2px solid var(--color-radical-red);
    }

  }

  .ant-drawer-close {
    display: none;
  }

  .ant-drawer-wrapper-body,
  .ant-drawer-header {
    background: var(--color-white);
  }

  .ant-form-item-explain,
  .ant-form-item-explain-error {
    display: none;
  }

  .ant-form-item {
    margin-bottom: 0 !important;
  }

  .ant-form {
    @extend %flex_column;
    min-height: 100%;
  }

  .ant-drawer-header {
    padding: 6rem 0 3.8rem 6rem;

    .ant-select-selection-item {
      font-weight: 600;
    }
  }


  .drawer__pop__confirm {
    @extend %position_center;
    background: var(--color-white);
    border-radius: 2.1rem;
    font-size: var(--font-size-medium);
    padding: 2rem;
    position: fixed;
    text-align: center;
    width: 50rem;
    z-index: 10;

    p {
      margin-bottom: 2rem;
    }
  }

  .ant-drawer-title {
    font-size: 2.4rem;
  }

  .ant-drawer-body {
    @extend %flex_column;
    padding: 2rem 6rem;
  }

  .ant-drawer-mask {
    //@include glassesEffect(0.1, 8px);
    background-color: rgba(100, 100, 100, 0.1) !important;
    backdrop-filter: blur(8px) saturate(160%) contrast(45%) brightness(140%);
    -webkit-backdrop-filter: blur(8px) saturate(160%) contrast(45%) brightness(140%);
  }


  .ant-drawer-content,
  .ant-drawer-content-wrapper {
    border-bottom-left-radius: 4rem;
    border-top-left-radius: 4rem;

  }

  .alert__error__fields {
    margin-bottom: 2rem;

    .ant-alert-message {
      color: var(--color-red-ribbon) !important;
    }
  }

  .block-confirm {

    margin-top: auto;
    text-align: center;


    .confirm {
      border: none;
      @include buttonRoundedLarge(linear-gradient(0.25turn, #417bf6, #a341f6), #fff)
    }

    .cancel {
      @include buttonRoundedLarge(white, var(--color-gray))
    }

    button {
      margin: 0 2rem !important;
    }
  }

  &.drawer__view {
    .block-confirm {
      display: none;
    }

    .ctn__qos__name input {
      margin-top: 0!important;
      //margin-bottom: auto;
    }

    //&.FirewallForm__view {
    .SelectMultiple {
      height: auto !important;

      .ant-select-selection-overflow-item {
        height: 4rem;
      }
    }

    //}
    .ant-select-selection-overflow, .ant-input, .ant-select-selector, .ant-select-selection-search-input, .ant-select-selection-item {
      display: flex;
      // justify-content: flex-end;
      padding: 0 !important;
      background: transparent !important;
      border: none;
      color: var(--color-woodsmoke);
      // text-align: right;
    }

    .ant-select-arrow {
      display: none;
    }
  }
}