.Rapport {

  .ctn__noData {

    position: relative;
    
    margin: 20rem 0;
    height: 25rem;
    & > div:nth-child(1) {
      display: none;
    }
    img {
      height: 20rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -100%);
    }
  }
  .ant-tabs-content-holder {
    border-radius: 2.1rem;
  }
  
  .ctn__graph__lines {
    @extend %light_shadow;
    background: var(--color-white);
    border-radius: 2.1rem;
  }

  .loader {
    height: 50rem;
    position: relative;
    img {
      @extend %position_center;
      position: absolute;
      width:40rem;

    }

  }
  .GraphLines {
    //height: 80rem;
    .graph {
      height: 70%
    }
  }


  .legend__label {
    font-weight: 500;
    margin-left: 2.5rem;
  }
}

.dark_theme {

}