.animation__fade {
  opacity: 0;
  transition: all 300ms ease-out;
}

.fade__visible__animation {
  opacity: 1;
  transition: all 500ms ease-in;
}

.fade__hidden__animation {
  opacity: 0;
  transition: all 500ms ease-in;
}