%full_size {
  height: 100%;
  width: 100%;
}

%cover {
  @extend %full_size;
  left: 0;
  position: absolute;
  top: 0;
}

%center {
  align-items: center;
  display: flex;
  justify-content: center;
}


%light_shadow {
  box-shadow: 0 0 20px rgba($color: #000000, $alpha: 0.04);
}


%position_center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

%position_horizontal_center {
  left: 50%;
  transform: translateX(-50%);
}

%position_vertical_center {
  top: 50%;
  transform: translateY(-50%);
}

%glasses_effect {
  -webkit-backdrop-filter: blur(15px) saturate(160%) contrast(45%) brightness(140%);
  backdrop-filter: blur(15px) saturate(160%) contrast(45%) brightness(140%);
  background-color: rgba(255, 255, 255, 0.7) !important;
}



%flex_column {
  display: flex;
  flex-direction: column;
}

%select_dropdown {
  @extend %glasses_effect;
  min-width: 31.5rem!important;
  //@mixin glassesEffect($opacity, $blur) {
  //}
  border-radius: 2.1rem;
  padding: 0;
  .ant-select-item {
    //padding: 1rem;
    font-size: 1.4rem;
    padding: 1.8rem;
  }
  .ant-select-item-option-active {
    background: var(--color-zircon);
  }

}


.dark-theme {
  %light_shadow {
    //box-shadow: 0 0 20px rgba($color: #fff, $alpha: 0.04);
  }
}