.TransitIp {

  .ctn__bandwidth {
    padding: 2rem 2.8rem;
    position: relative;
  }

  .ctn__transit__chart {
    height: 28rem;
    margin-top: 1rem;
    overflow: hidden;
    position: relative;
  }
  .chartdiv__transitIp {
    height: 128%;
    position: absolute;
    width: 110%;
    @extend %position_center;
    margin: 0 auto;
    & > div {
      left: 50%!important;
      transform: translate(-50%, -15%);
    }
  }

  .ctn__arrow {
    @extend %position_horizontal_center;
    bottom: 1.4rem;
    display: flex;
    height: 15%;
    justify-content: center;
    position: absolute;
    &:before {
      @extend %position_center;
      background: red;
      content: '';
      height: 45%;
      position: absolute;
      width: 0.1rem;
    }
    button {
      height: 100%;
      margin: 0 1.4rem;
      width: 4rem;

      &:first-child {
        fill: var(--color-dodger-blue);
        transform: rotate(180deg);
      }

      &:last-child {
        fill: var(--color-red-ribbon);
      }
    }
  }

  .ant-select {
    font-weight: 600;
    width: auto;
    z-index: 1000;
  }
  .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
    font-size: 1.4rem;
    font-weight: 700;
    outline: none;
    padding: 0 1.8rem 0 0 !important;
  }
}


