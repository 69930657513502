@keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
    transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }

  24% {
    opacity: 1;
  }

  40% {
    -webkit-transform: translateY(-24px);
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  65% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  82% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  93% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}

@keyframes float {
  0% {
    // box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }

  50% {
    // box-shadow: 0 25px 10px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-12px);
  }

  100% {
    // box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}

@keyframes dropIn {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(-15px);
  }

  100% {
    transform: translatey(0px);
  }
}

@keyframes move-eye-skew {
  0% {
    transform: none;
  }

  20% {
    transform: translateX(-68px) translateY(30px) skewX(15deg) skewY(-10deg) scale(0.95);
  }

  25%,
  44% {
    transform: none;
  }

  50%,
  60% {
    transform: translateX(68px) translateY(-40px) skewX(5deg) skewY(2deg) scaleX(0.95);
  }

  66%,
  100% {
    transform: none;
  }
}

@keyframes back-and-forth {
  0% {
    -webkit-transform: rotateY(40deg);
    -moz-transform: rotateY(40deg);
    -ms-transform: rotateY(40deg);
    -o-transform: rotateY(40deg);
    transform: rotateY(40deg);
  }

  40%,
  50% {
    -webkit-transform: rotateY(-40deg);
    -moz-transform: rotateY(-40deg);
    -ms-transform: rotateY(-40deg);
    -o-transform: rotateY(-40deg);
    transform: rotateY(-40deg);
  }

  90%,
  100% {
    -webkit-transform: rotateY(40deg);
    -moz-transform: rotateY(40deg);
    -ms-transform: rotateY(40deg);
    -o-transform: rotateY(40deg);
    transform: rotateY(40deg);
  }
}

@keyframes bubble-anim {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scaleY(0.95) scaleX(1.05);
  }

  48% {
    transform: scaleY(1.1) scaleX(0.9);
  }

  68% {
    transform: scaleY(0.98) scaleX(1.02);
  }

  80% {
    transform: scaleY(1.02) scaleX(0.98);
  }

  97%,
  100% {
    transform: scale(1);
  }
}