%light_shadow {
  box-shadow: 0 0 20px rgba($color: #000000, $alpha: 0.04);
}

%position_center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

%position_horizontal_center {
  left: 50%;
  transform: translateX(-50%);
}

%position_vertical_center {
   top: 50%;
   transform: translateY(-50%);
 }

%glasses_effect {

  -webkit-backdrop-filter: blur(15px) saturate(160%) contrast(45%) brightness(140%);
  backdrop-filter: blur(15px) saturate(160%) contrast(45%) brightness(140%);
  -webkit-box-shadow: 0px 6px 22px 11px rgba(0,0,0,0.1); 
  box-shadow: 0px 6px 22px 11px rgba(0,0,0,0.1);
  background-color: rgba(255, 255, 255, 0.7) !important;
}

@mixin ctnFields {
    & > div {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2rem;

      label {
        font-size: 1.4rem;
        margin-bottom: auto;
        margin-top: auto;
      }

    }
}

@mixin buttonRoundedBorder($color) {
  background: none;
  border-radius: 25vw !important;
  border: 1px solid $color;
  color: $color !important;
  font-size: var(--font-size-xs) !important;
  height: unset;
  margin: 0;
  padding: var(--spacing-10) var(--spacing-15);
  &:hover {
    background: $color;
    color: var(--color-white)!important;
    transition: all 400ms;
  }
}

@mixin add-color($argument) {
  $string: unquote($argument);

  &--#{$string} {
    color: unquote('#' + $argument);
  }
}