.NavTop {
  background: var(--color-alabaster);
  display: flex;
  height: 20rem;
  justify-content: space-between;
  padding: 0 7.7rem;
  position: fixed;
  right: 0;
  top: 0;
  width: calc(100% - 33rem);
  z-index: 2;
  .select__boitier {
    height: 4.4rem;
    margin: auto 0;
    width: 51.4rem;
    .ant-select-selector {
      border-radius: 30rem;
    }
  }

  .ctn__me {
    display: flex;
    margin: auto 0;

    .ant-select {
      font-weight: 600;
      margin: auto 2.5rem auto;
      .ant-select-selector {
        border-radius: 30rem!important;

      }
    }

    .SelectSimple {
      width: auto;
      min-width: 15rem;
      max-width: 25rem;
    }

    .ctn__img {
      border-radius: 50%;
      height: 5.5rem;
      overflow: hidden;
      width: 5.5rem;
      img {
        @extend %full_size;
        object-fit: cover;
      }
    }

  }
}

.select__me {
  left: inherit!important;
  right: 15.7rem!important;
}