

body {
  margin: 0;
  font-family: 'Poppins',  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*min-height: 100%;*/
  /*height: 100%;*/
  /*min-width: 100%;*/
  /*width: 100%;*/
  /*background: var(--color-alabaster);*/

  /*position: fixed;*/

  /*left: 0;*/
  /*top: 0;*/
}



html, .App, #root {
  /*min-height: 100%;*/
  /*height: 100%;*/
  /*min-width: 100%;*/
  /*width: 100%;*/
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
a {
  text-decoration: none;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul li {
  color: var(--color-woodsmoke);
}

.d-none {
  display: none!important;
}

.text-left {
  text-align: left;
}

.ant-tabs-tab, .ant-collapse, .ant-tabs-content {
  font-size: var(--font-size-medium);
}

.d-flex {
  display: flex;
}
/*input:- {*/
/*  background-image: none !important;*/
/*  background-color: var(--color-white)!important;*/
/*  color: var(--color-white) !important;*/
/*}*/


.error_field {
  border: 1px solid var(--color-radical-red)!important;
  border-radius: 0.3vw;
}

label {
  font-size: var(--font-size-medium);
}

.ant-input-number-input {
  font-size: var(--font-size-medium);
  height: auto;
  padding: 0 var(--spacing-5);
}

.ant-input-number-handler-wrap {
  width: 40%;
}


/*.ant-input-number-handler {*/
/*  width: 30%;*/
/*}*/

