.ContainerMainPageTabs {

  .ant-tabs-nav-wrap {
    overflow: visible!important;
  }
  .ant-tabs-tab {
    border-radius: 0.7rem;
    color: var(--color-silver);
    font-weight: 500;
    margin: 0 6rem 0 0;
    padding: 0.5rem 2.2rem;
    svg {
      color: var(--color-silver);
    }
    &:hover {
      color: var(--color-woodsmoke);
    }
  }

  .ant-tabs-nav {
    margin-bottom: 4rem;
  }

  .ant-tabs-nav::before {
    border-bottom: none;
  }
  .ant-tabs-ink-bar {
    @extend %light_shadow;
    background: var(--color-solitude);
    border-radius: 0.7rem;
    height: 100%!important;
    z-index: -1;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--color-dodger-blue);
    svg {
      color: var(--color-dodger-blue);
    }
  }

  .ant-tabs-tab-btn:active {
    color: var(--color-woodsmoke);
  }

  .tab_tooltip {
    display: flex;
  }
  .btn__tooltip {
    height: 2rem;
    margin: auto 0 auto 1rem;
    position: relative;
    width: 2rem;
    svg {
      @extend %position_center;
      height: 2rem;
      position: absolute;
      width: 2rem;
    }
  }
}