
.BarActionsTable {

  @extend %glasses_effect;
  @extend %light_shadow;
  background-color: transparent;
  border-radius: 0.8vw;
  bottom: 2rem;
  display: flex;
  font-size: var(--font-size-medium);
  left: 56%;
  transform: translateX(-50%);
  z-index: 11;
  overflow: scroll;
  padding: 0.6vw 0.7vw;
  position: fixed;
  background-color: red !important;

  .option_bar_ctn-1 {
    background-color: var(--color-dodger-blue);
    border-radius: 0.5vw;
    color: var(--color-white);
    display: flex;
    margin: auto 0;
    padding: 0.7vw;
  }
  .section-ipsec-01 {
    margin-left: 1.6vw;
  }
  .section-ipsec-02 {
    display: flex;
    margin-left: 1.7vw;

    .action-edit-table {
      display: flex;
      height: 100%;
      margin-right: 4.2vw;

      div {
        //border: 1px solid red;
        //width: 3vw;
        @extend %flex_column;
        color: var(--color-gray);
        cursor: pointer;
        font-size: var(--font-size-xs);
        height: 100%;
        justify-content: space-around;
        margin-left: 1vw;
        margin-right: 1vw;
        text-align: center;
        img {
          margin-left: auto;
          margin-right: auto;
        }
        svg {
          height: 1.3vw;

          fill: var(--color-gray);
        }


        &:hover {
          color: var(--color-dodger-blue);

          svg {
            fill: var(--color-dodger-blue);
          }
        }

        p {
          margin: 0;
        }
      }
    }


  }

  .close-edit-table {
    //display: none;
    border-left: 1px solid var(--color-mercury);
    cursor: pointer;
    display: flex;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 3.7vw;
    &:before, &:after {
      @extend %position_center;
      background: var(--color-silver);
      border-radius: 2rem;
      content: '';
      height: 2.5rem;
      position: absolute;
      width: 0.15rem;
    }
    &:before {
      transform: translate(-50%, -50%) rotate(45deg)!important;
    }
    &:after {
      transform: translate(-50%, -50%) rotate(-45deg)!important;
    }

    img {
      height: 20%;
      margin: auto;
    }
    svg {
      color: var(--color-mercury);
      font-size: 1vw;
      margin: auto;
    }
  }
}