.InputNameSwitch {
  @extend %position_vertical_center;
  display: flex;
  justify-content: space-between;
  left: 8.4rem;
  position: absolute;
  width: calc(100% - 6.4rem - 3rem);

  .ant-switch {
    margin: auto 0;
  }

  div {
    margin: auto 0;
  }
  .DropdownCollapse{
    background-color: transparent;
    box-shadow: none;
  }
  button {
    border: none;
    outline: none;
    svg {
      height: 0.5rem;
      fill: var(--color-gray);
    }
  }
}

.dropdown__inputNameSwitch {
  background: red;
  @extend %glasses_effect;
  @extend %light_shadow;
  border-radius: 0.8vw;
  padding-top: 0 !important;
  @extend %flex_column;

  button {
    color: var(--color-gray);
    font-weight: 500;
    min-width: 14rem;
    padding: 1rem;
  font-size: var(--font-size-medium);
    &:hover {
      background: var(--color-zircon);
      transition: all 200ms;
    }
  }
}
