.dragzone {
  position: relative;
  width: 100%;
  .item {
    transition: all 200ms;
  }

  .is-dragged {
    opacity: 0.5;
    transition: none;
    z-index: 9;
  }
}