.Interfaces {
  .numberInterface {
    position: absolute;
    right: 25rem;
    font-weight: 600;
  }

  .InterfaceVirtuelle {
    background-color: #f4f4f4 !important;
  }
  .btn__add__interface {
    margin-bottom: 2rem;
  }
}