
@import 'base/reset';

@import 'utils/mixins';
@import 'utils/placeholders';
@import 'utils/variables';

@import 'base/base';

@import "../common/AddButton/AddButton";
@import "../common/BarTableAction/BarTableAction";
@import "../common/BasicDrawerForm/BasicDrawerForm";
@import "../common/BasicTable/BasicTable";
@import "../common/ContainerMainPageTabs/ContainerMainPageTabs";
@import "../common/DragNDrop/DragNDrop";
@import "../common/EquipmentsMap/EquipmentsMap";
@import "../common/Fade/Fade";
@import "../common/InputNameSwitch/InputNameSwitch";
@import "../common/Layout/Layout";
@import "../common/Layout/NavTop/NavTop";
@import "../common/Layout/NavTop/SettingsButton/SettingsButton";
@import "../common/Layout/Navbar/Navbar";
@import "../common/SelectMultiple/SelectMultiple";
@import "../common/SelectSearch/SelectSearch";
@import "../common/SelectSimple/SelectSimple";
@import "../common/SmoothScrolling/SmoothScrolling.scss";
@import "../common/TextField/TextField";


@import "../views/Equipements/Equipements";
@import "../views/Notifications/Notifications";
@import "../views/Rapport/Rapport";

@import "../components/Dashboard/Widgets/AccessIp/AccessIp";
@import "../components/Dashboard/Widgets/Banner/Banner";
@import "../components/Dashboard/Widgets/TrafficClassification/TrafficClassification";
@import "../components/Dashboard/Widgets/TransitIp/TransitIp";
@import "../components/Dashboard/Widgets/Widgets";
@import "../components/Dashboard/Widgets/FiltrageWeb/FiltrageWeb";

@import "../components/Equipements/Carte/Carte";
@import "../components/Equipements/Liste/EquipmentForm/EquipmentForm";
@import "../components/Equipements/Liste/EquipmentForm/Informations/ChargesPanel/ChargesPanel";
@import "../components/Equipements/Liste/EquipmentForm/Informations/DetailsPanel/DetailsPanel";
@import "../components/Equipements/Liste/EquipmentForm/Interfaces/InterfacePanel/Details/Detail";
@import "../components/Equipements/Liste/EquipmentForm/Interfaces/InterfacePanel/Dhcp/Dhcp";
@import "../components/Equipements/Liste/EquipmentForm/Interfaces/InterfacePanel/Dhcp/Options/Options";
@import "../components/Equipements/Liste/EquipmentForm/Interfaces/InterfacePanel/Dhcp/Options/Option/Option";
@import "../components/Equipements/Liste/EquipmentForm/Interfaces/InterfacePanel/Dns/Dns";
@import "../components/Equipements/Liste/EquipmentForm/Interfaces/InterfacePanel/Dns/Entry/Entry";
@import "../components/Equipements/Liste/EquipmentForm/Interfaces/InterfacePanel/Relais/Relais";
@import "../components/Equipements/Liste/EquipmentForm/Interfaces/InterfacePanel/Details/ParentInterfaces/ParentInterfaces";
@import "../components/Equipements/Liste/EquipmentForm/Passerelles/Gateway/Gateway";
@import "../components/Equipements/Liste/EquipmentForm/Routages/Routage/RoutageStatus/RoutageStatus";
@import "../components/Equipements/Liste/EquipmentForm/Informations/GroupsPanel/GroupsPanel.scss";
@import "../components/Equipements/Liste/Liste.scss";

@import "../components/Labo/CloudConnector/IpsecForm/IpsecForm";
@import "../components/Labo/CloudConnector/IpsecForm/Phase/Phase";
@import "../components/Labo/InterConnexion/BarInterconnexionActions/BarInterconnexions";
@import "../components/Labo/InterConnexion/InterConnexion";
@import "../components/Labo/Nomade/NomadeForm/NomadeForm";
@import "../components/Labo/Qos/Qos";
@import "../components/Labo/Qos/QosForm/GlobalViews/GlobalViews";
@import "../components/Labo/Qos/QosForm/MyApps/MyApps";
@import "../components/Labo/Qos/QosForm/QosForm";
@import "../components/Labo/Modele/ModeleForm/AddModal/AddModal";
@import "../components/Labo/Modele/ModeleForm/PasserelleForm/PasserelleForm.scss";

@import "../components/Rapport/Analyse/Analyse";
@import "../components/Rapport/Filters/Filters.scss";
@import "../components/Rapport/Trafic/Trafic";
@import "../components/Rapport/GraphLine/GraphLine.scss";
@import "../common/GraphLines/GraphLines.scss";

@import "../components/Securite/Firewall/FirewallForm/FirewallForm";
@import "../components/Securite/Firewall/Regles/Regles.scss";
@import "../components/Securite/Firewall/Regles/ReglesTable/ReglesTable";
@import "../components/Securite/Firewall/Regles/RegleForm/RegleForm.scss";
//.ant-select-dropdown {
//  display: block!important;
//}


.ant-picker-suffix {
  height: 2rem!important;
  span {
    height: 100%;
    svg {
      height: 100%;
    }
  }
}


.row-dragging {
  background: #fafafa!important;
  border: 1px solid #ccc!important;
  z-index: 1000!important;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible!important;
}

.ant-table-row {
  visibility: visible!important;
  opacity: 1!important;
}