html {
  font-size: calc(100vw / 2560 * 10);
  line-height: 1;
  @media screen and (max-width: 1920px) {
    font-size: calc(100vw / 2200 * 10);
  }

}



a {
  color: inherit;
  outline: none;
  text-decoration: none;
}


button {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  outline: none;
}


.ant-select {
  font-size: 1.8rem;
  line-height: 1;
}


.ant-select-item {
  font-size: var(--font-size-medium) !important;
}

.ant-select-selector {
  color: var(--color-woodsmoke);
  height: 100% !important;
  padding: 0.4rem 2.5rem 0.4rem 1.5rem !important;
}


.ant-select-selection-item {
  align-items: center;
  display: flex;
  line-height: inherit !important;
  vertical-align: middle;
}

.ant-select-selection-search {
  left: 1.5rem !important;
}


.ant-select-selection-search-input {
  height: 100% !important;
}

.ant-select-selection-placeholder {
  align-items: center;
  display: flex;
  line-height: inherit !important;
  padding-right: 1.8rem !important;
  vertical-align: middle;
}

.ant-select-arrow {
  font-size: 1.8rem;
  height: 1.2rem;
  margin-top: 0;
  right: 1.1rem;
  transform: translateY(-50%);
  width: 1.2rem;

  span {
    @extend %full_size;

    svg {
      @extend %full_size;
    }
  }

  svg path {
    @extend %full_size;
    d: path("M0,0,23.84,23.84,47.68,0Z") !important;
    fill: var(--color-woodsmoke);
    transform: translateY(40%) scale(20) !important;

  }

}

.ant-tabs {
  overflow: visible;
}

g {
  font-family: 'Poppins';
}

h3 {
  font-size: 2.4rem;
  font-weight: 600;
}

g[aria-labelledby] {
  display: none;
}

g[aria-label="Legend"] {
  g[aria-labelledby] {
    display: block;
    font-size: var(--font-size-medium);
  }

}

h1, h2, h3, h4, h5, p, label, input, button, .ant-picker-input > input {
  color: var(--color-woodsmoke);
}

.dark-theme {
  .ant-select-selector {
    background: #272a31 !important;
    border: none !important;
  }

  input{
    background: #272a31;
    border: none !important;
  }

  .ant-picker {
    background: #272a31;
    border: none !important;
    padding: 0.5rem 1rem 0.5rem;
  }
  tspan {
    color: var(--color-woodsmoke);
    fill: var(--color-woodsmoke);
  }
}



.ant-switch {
  font-size: var(--font-size-medium);
  line-height: 0.15715rem;
  min-width: 4.4rem;
  height: 2.2rem;
  line-height: 2.2rem;
  border-radius: 10rem;
  .ant-switch-handle {
    top: 0.2rem;
    left: 0.2rem;
    width: 1.8rem;
    height: 1.8rem;}

  .ant-switch-inner {
    display: block;
    margin: 0 0.7rem 0 2.5rem;
    color: #fff;
    font-size: 1.2rem;
  }


}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 1.8rem - 0.2rem);
}

.ant-switch-checked .ant-switch-inner {
  margin: 0 2.5rem 0 0.7rem;
}


.ant-alert {
  font-size: var(--font-size-medium);
}

.block-confirm, .ant-modal-footer{

  margin-top: auto;
  text-align: center;


  .confirm {
    @include buttonRoundedLarge(linear-gradient(0.25turn, #417bf6, #a341f6), #fff)
  }

  .cancel {
    @include buttonRoundedLarge(white, var(--color-silver))
  }

  button {
    margin: 0 2rem !important;
  }
}

input[type='checkbox'] {
  height: 2rem;
}

.ant-collapse-arrow {
  svg {
    height: 2rem;
  }
}

.ant-picker-suffix {
  height: 2rem!important;
  span {
    height: 100%;
    svg {
      height: 100%;
    }
  }
}

.ant-radio-inner {
  height: 2.6rem;
  width: 2.6rem;

}