.TraficClassification {
  position: relative;
  .filtre {
    position:relative;
    left: 16vw;
  }
  #chartdiv__trafficClassification {
    height: 110%;
    position: relative;
    top: 2rem;
    right: 1rem;
  }
  button {
    position: absolute;
    right: 0;
    top:1.2rem;
    transform: translateY(-50%);
    svg {
      height: 0.5rem;
    }
  }
  tspan {
    font-weight: 600;
  }
}
.HorizontallyChart {
  position: relative;
  #chartdiv__horizontallyChart {
    height: 120%;
    position: relative;
    right: 2rem;
    top: 1rem;
  }
  button {
    position: absolute;
    right: 0;
    top:1.2rem;
    transform: translateY(-50%);
    svg {
      height: 0.5rem;
    }
  }
  tspan {
    font-weight: 600;
  }
}

.trf__dropdown {
  @extend %glasses_effect;
  @extend %light_shadow;
  border-radius: 0.8vw;
  padding-top: 0!important;
  .duration__list {
    @extend %flex_column;
  }

  button {
    color: var(--color-gray);
    font-weight: 500;
    min-width: 14rem;
    padding: 1rem;
    &:hover {
      background: var(--color-zircon);
      transition: all 200ms;
    }
  }
}

