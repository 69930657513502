
:root {

  --font-size-large: 1.8rem;
  --font-size-medium: 1.4rem;
  --font-size-small: 1rem;
  --font-size-xlarge: 2.4rem;



  --color-alabaster: #fcfcfc;
  --color-caribbean-green: #0dd2a6;
  --color-dodger-blue: #417bf6;
  --color-gray: #7e7e7e;
  --color-hawkes-blue: #d7e1fc;
  --color-mercury: #e7e7e7;
  --color-milk: #F6F7FE;
  --color-radical-red: #ff304a;
  --color-red-ribbon: #f21934;
  --color-salmon: #ff8f68;
  --color-silver: #c7c7c7;
  --color-solitude: #e6f0fe;
  --color-white: #fff;
  --color-woodsmoke: #161617;
  --color-zircon: #f5f8ff;

  .dark-theme {







    --color-alabaster: #121315 ;
    --color-caribbean-green: #01a6a0;
    --color-dodger-blue: #8c5bf5;
    --color-mercury: #151515;
    --color-radical-red: #f6335f;
    --color-solitude: #272a31;
    --color-white:  #1b1c1e;
    --color-woodsmoke: #aaacad;
  }

  --base-height-table-body: 26vw;



  --font-size-l: 0.69vw;
  --font-size-m: 0.59vw;
  --font-size-xl: 1vw;
  --font-size-xs: 0.49vw;
  --font-size-xxl: 1.27vw;
  --font-size-xxxl: 1.5625vw;
  --navbar-left-size: 12vw;


  --color-degrade-violet-bleu: linear-gradient(0.25turn,#417bf6, #a341f6);

  --color-gradient-nav: linear-gradient(0.4turn, #cfdefd 50%, #e4d7f5);

  --border-radius-1 : 1vw;


  --img-title-page-height: 1.27vw;


  --bg-blue-active: #397df8;
  --bg-input-grey: #f3f3f3;


  --height-input-select-rounded: 1.3vw;
  --height-input-text-form: 1.660vw;
  --width-input-text-form: 12.1875vw;

  --height-input-rectangular: 1.5vw;
  --width-input-rectangular: 12.8vw;

  --spacing-10: 0.5vw;
  --spacing-120: 8.333vw;
  --spacing-150: 10.417vw;
  --spacing-15: 1.042vw;
  --spacing-180: 12.5vw;
  --spacing-20: 1.5vw;
  --spacing-30: 2.083vw;
  --spacing-45: 3.125vw;
  --spacing-5: 0.25vw;
  --spacing-60: 4.167vw;
  --spacing-75: 5.208vw;
  --spacing-90: 6.25vw;


  @media (max-width: 1600px) {
    --font-size-l: calc(0.69vw * 1.2);
    --font-size-m: calc(0.59vw * 1.2);
    --font-size-s: calc(0.55vw * 1.2);
    --font-size-xl: calc(1vw * 1.2);
    --font-size-xs: calc(0.49vw * 1.2);
    --font-size-xxl: calc(1.27vw * 1.2);
    --font-size-xxxl: calc(1.5625vw * 1.2);

    --height-input-select-rounded: calc(1.3vw * 1.2);
    --height-input-text-form: calc(1.660vw * 1.2);

  }
}
